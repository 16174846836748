import {inject, bindable, bindingMode, customElement, containerless} from 'aurelia-framework'; 
 
@containerless 
@customElement("selectbox") 
export class selectBox { 
  @bindable value: string 
  @bindable allowEmpty:boolean = true 
  @bindable required: boolean = false 
  @bindable placeholder = 'Vennligst velg...' 
  @bindable options: any[] 
  @bindable valueField: string 
  @bindable labelField: string 
} 

import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { AsyncErrorHandler } from 'lib/ui';
import { SystemFileStorageService } from 'services/system-file-storage-service';
import { PdfPreviewController } from './pdf-preview-controller';

export type FileListItem = {
  id: number;
  type: 'system' | 'user';
};

type FileEntry = {
  id: number;
  name: string;
  type: 'system' | 'user';
};

@autoinject()
export class TableFileButton {
  @bindable()
  disabled: boolean = false;

  @bindable()
  classes: string = '';

  @bindable()
  files: FileListItem[] = [];

  constructor(
    private t: I18N,
    private systemFileService: SystemFileStorageService,
    private previewController: PdfPreviewController
  ) {
    //
  }

  attachments: FileEntry[] = [];

  @AsyncErrorHandler
  protected async download(file: FileEntry) {
    await this.systemFileService.download(file.id, true);
  }

  @AsyncErrorHandler
  protected async preview(file: FileEntry) {
    const blob = (await this.systemFileService.download(file.id, false)) as Blob;
    void this.previewController.openPdfPreview({
      blob,
      fileName: file.name,
    });
  }

  async getFileMeta() {
    this.isLoading = true;
    this.attachments = [];
    const metas = await Promise.all(
      this.files.map((file) => {
        return this.systemFileService.getMetaData(file.id);
      })
    );

    metas.map((meta, index) => {
      this.attachments.push({
        id: this.files[index].id,
        name: meta.FileName,
        type: 'system',
      });
    });
    this.isLoading = false;
  }

  protected isLoading: boolean = false;

  protected async showAttachments(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    console.log(this.files);

    const tableOpener = (e.target as HTMLElement).closest('.table-dropdown');
    const posOfDropdownBox = tableOpener.getBoundingClientRect();
    const dropdownContent = tableOpener.getElementsByClassName('content')[0] as HTMLElement;
    const dropDownToggler = tableOpener.getElementsByClassName('toggle')[0] as HTMLElement;

    dropdownContent.style.width = '0px';
    dropdownContent.style.height = '0px';

    setTimeout(() => {
      dropdownContent.style.width = 'auto';
      dropdownContent.style.height = 'auto';

      const contentSizes = dropdownContent.getBoundingClientRect();
      let offsetPos = posOfDropdownBox.height;
      // Move dropdown up if it's too close to the bottom of the screen
      if (document.body.clientHeight - posOfDropdownBox.top < 400) {
        offsetPos = -contentSizes.height;
      }

      const w = dropDownToggler.getBoundingClientRect().width || 0;
      dropdownContent.style.width = 'auto';
      dropdownContent.style.visibility = 'hidden';
      dropdownContent.style.top = posOfDropdownBox.top + offsetPos + 'px';
      dropdownContent.style.left = posOfDropdownBox.left - contentSizes.width + w + 'px';
      dropdownContent.style.visibility = 'visible';
    }, 100);

    void this.getFileMeta();
  }
}

// Component based on: https://github.com/codefreeze8/aurelia-select2
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, customElement, inject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import $ from 'jquery';
import 'select2';

type Select2Option = {
  templateSelection: (item: unknown) => unknown;
  templateResult: (item: unknown) => unknown;
  disabled?: boolean;
};

@customElement('select2')
@inject(Element, I18N, EventAggregator)
export class Select2 {
  public lang: string;

  @bindable protected name = null; // name/id of custom select
  //  @bindable selected = []; // default selected value
  @bindable protected options = null; // array of options with id/name properties
  @bindable protected optionsQuery: Promise<Array<{ Id?: number | string; Inactive?: boolean; IsDeleted?: boolean }>>;
  @bindable protected placeholder = '';
  @bindable protected allowClear = true;
  @bindable protected includeEmptyOption = true;
  @bindable protected multiple = false;
  @bindable protected selected: string;
  @bindable protected valueField: string;
  @bindable protected labelField: string;
  @bindable protected sortfield: string;
  @bindable protected sortDirection: 'ASC' | 'DESC' = 'ASC';
  @bindable protected disabled = false;
  @bindable protected customClass = '';

  private element: HTMLElement;
  private SELECT: any;
  private selectedDecoupled: string | number;

  private label: string;
  private isPerformingQuery = false;

  constructor(
    element: HTMLElement,
    private i18N: I18N,
    private eventAggregator: EventAggregator
  ) {
    this.element = element;
    this.SELECT = ''; // pointer to the underlying 'SELECT' element
    this.selectedDecoupled = '';
  }

  protected attached() {
    this.SELECT = $(this.element).find('select');
    this.lang = this.i18N.getLocale();

    const select2options: Select2Option = {
      templateSelection: this.formatHtml,
      templateResult: this.formatHtml,
      disabled: !!this.disabled || false,
    };

    // if (this.disabled) {
    //   select2options.disabled = true;
    // }

    const select = this.SELECT.select2(select2options);
    this.getOptions();

    select.on('change', (event: { originalEvent: string; target: HTMLInputElement }) => {
      // don't propagate endlessly
      // see: http://stackoverflow.com/a/34121891/4354884
      if (event.originalEvent || this.isPerformingQuery) {
        return;
      }

      // dispatch to raw select within the custom element
      // bubble it up to allow change handler on custom element
      const notice = new CustomEvent('change', {
        detail: {
          value: event.target.value,
          values: this.SELECT.select2('data'),
        },
        bubbles: true,
      });
      $(this.SELECT)[0].dispatchEvent(notice);
    });

    this.eventAggregator.subscribe('lang-changed', () => {
      this.lang = this.i18N.getLocale();
    });

    setTimeout(() => {
      this.element.querySelector('.select2-selection')?.addEventListener('focus', () => {
        this.SELECT.select2('open');
      });
    }, 100);
  }

  // Enable dynamic changing of disabled attribute
  protected disabledChanged(newV: unknown) {
    if (!this.SELECT) {
      return;
    }

    if (!newV) {
      this.SELECT.select2({ disabled: false });
    }
    if (newV) {
      this.SELECT.select2({ disabled: true });
    }
  }

  protected selectedChanged(newV: string | number) {
    this.getOptions();
    this.selectedDecoupled = newV;
    if (this.SELECT) {
      this.SELECT.val(this.selectedDecoupled);
      this.SELECT.trigger('change');
    }
  }

  private getOptions() {
    if (this.optionsQuery) {
      this.isPerformingQuery = true;
      void this.optionsQuery.then((res) => {
        this.isPerformingQuery = false;
        this.options = res.filter((x) => x.Id == this.selected || (!x.Inactive && !x.IsDeleted));
      });
    }
  }

  // to allow pass-thru of HTML which comes as glyph icons.
  private formatHtml(item: { text: string; class: string; element: HTMLElement }) {
    let className = '';
    if (item.text.indexOf('<') > -1) {
      // must pass back as jquery object to by-pass string-sanitizer
      const $state = $("<span class='" + item.class + "'>" + item.text + '</span>');
      return $state;
    }
    if (item.element) {
      className = item.element.className;
    } else {
      className = '';
    }
    return $("<span class='" + className + "'>" + item.text + '</span>');
  }

  // Aurelia lifecycle
  protected detached() {
    if (this.SELECT && this.SELECT.hasClass('select2-hidden-accessible')) {
      this.SELECT.select2('destroy');
      this.SELECT.off('change');
    }
  }
}

import { EventSubscriber, autoinject, bindable, containerless, observable } from 'aurelia-framework';
import './loader.scss';
import './save-cancel.scss';
import { ConfirmDialog } from 'components/dialogs/confirm/confirm-dialog';
import { I18N } from 'aurelia-i18n';
import { ValidateEvent, ValidationController } from 'aurelia-validation';

@containerless()
@autoinject()
export class SaveCancel {
  @bindable()
  @observable()
  isSaving: boolean = false;
  isSavingChanged(saving: boolean) {
    if (saving) {
      this.doneSaving = false;
      return;
    } else {
      // Use a timeout to prevent the spinner from flashing on screen for a split second
      setTimeout(() => {
        this.doneSaving = !saving;
      }, 600);
    }
  }

  doneSaving = true;

  @bindable()
  onSave: () => void;

  @bindable()
  onCancel?: () => void;

  @bindable()
  onDelete?: () => void;

  @bindable()
  disableDelete?: boolean;

  @bindable()
  deleteConfirmationText?: string;

  @bindable()
  disabled = false;

  @bindable()
  validationController?: ValidationController;

  async preDeleteConfirmation() {
    if (!this.onDelete || this.disableDelete) return;

    const result = await this.confirmation.confirmYesNo(
      'general.delete',
      this.deleteConfirmationText ? this.deleteConfirmationText : this.t.tr('dialog.areYouSure')
    );

    if (result) {
      this.onDelete?.();
    }
  }

  constructor(
    private confirmation: ConfirmDialog,
    private t: I18N
  ) {}

  protected subscriptions: {dispose: ()=> void}[]  = [];

  protected validator(e: ValidateEvent){
    console.log('Validation event', e);
      this.disabled = Boolean(e.errors.length);
  }

  attached() {
    if (this.validationController) {
      this.subscriptions.push(this.validationController.subscribe(this.validator.bind(this)));
    }
  }

  detached() {
    if (this.subscriptions?.length) {
      this.subscriptions.forEach(sub => sub.dispose());
    }
  }
}

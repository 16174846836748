import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, computedFrom, DOM, inject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';

@inject(DOM.Element, I18N, EventAggregator, Router)
export class RouteTabs {
  @bindable public activeTabIndex: number = 0;
  public element: HTMLElement;
  public tabsEl: Array<HTMLElement>;
  public activeTabIdx: Number;

  constructor(_DOMElement, private i18n: I18N, public EventAggregator: EventAggregator, private router) {
    this.i18n = i18n;
    this.element = _DOMElement;
    this.tabsEl = [];
    this.activeTabIdx = 0;

    // TODO: this does not currently work:
    EventAggregator.subscribe('i18n:locale:changed', (payload) => {
      this.tabsEl.forEach(tabEl => {
        this.i18n.updateTranslations(tabEl);
      });
    });
  }

  @computedFrom('tabsEl')
  get tabsHeaders() {
    return this.tabsEl.map((t) => {
      return {
        name: this.i18n.tr(t.getAttribute('name')) + (t.getAttribute('name-suffix') || ''),
        css: { width: `${this.tabWidth}%` }
      };
    });
  }

  @computedFrom('tabsEl', 'activeTabIdx')
  get slideCss() {
    const width = `${this.tabWidth}%`;
    return {
      width,
      left: `calc(${this.activeTabIdx} * ${width})`
    };
  }

  get tabWidth() {
    let empty: Array<HTMLElement>;
    return 100 / ((this.tabsEl || empty).length || 0);
  }

  public attached() {
    if (!this.element) { return; }
    // TODO: setup mutation observer to observe tab slot changes and rebuild the tab headers if needed
    this.tabsEl = [].slice.call(this.element.querySelectorAll('tab'));
    this.changeTab(0);
  }

  public changeTab(index: Number) {
    this.activeTabIdx = index;
    this.tabsEl.forEach((t, i) => {
      if (index === i) {
        t.setAttribute('data-active', 'true');
        if (t.getAttribute('data-route')) {
          this.router.navigateToRoute(t.getAttribute('data-route'));
        }
      } else {
        t.removeAttribute('data-active');
      }
    });
  }
}

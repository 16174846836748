import { bindingMode, TaskQueue } from 'aurelia-framework';
import {autoinject, customElement, bindable, customAttribute} from 'aurelia-framework';

@autoinject()
export class NumericInputWithTolerance {
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string;  
  @bindable({defaultBindingMode: bindingMode.twoWay}) valueToleranceUp: string = "0";  
  @bindable({defaultBindingMode: bindingMode.twoWay}) valueToleranceDown: string = "0";  
  @bindable unit: string;
  @bindable name: string
  @bindable disabled: Boolean = false;
}

import { bindingMode, TaskQueue } from 'aurelia-framework';
import {autoinject, customElement, bindable, customAttribute} from 'aurelia-framework';

@autoinject()
export class NumericInput {
  @bindable({defaultBindingMode: bindingMode.twoWay}) value: string;  
  @bindable private disabled: boolean = false;
  @bindable private focusInput: boolean = false;
  @bindable classes: string;
  @bindable unitClasses: string = 'unit';
  @bindable unit: string;
  @bindable unitT: string;
  @bindable inFocus: any;
  @bindable maxlength: number;
  @bindable preventNegativeNumbers: boolean = false;
  @bindable allowValueChangeByArrows: boolean = false;
  @bindable name: string;
  @bindable placeholder: string = null;
  @bindable inputType: string = 'text';

  constructor(private element: Element, private taskQueue: TaskQueue) {}

  private paste(event) {

    let paste = (event.clipboardData || window['clipboardData']).getData('text');
    paste = paste.replace(',', '.');

    if (event.srcElement.type === 'text') {
      let startPosition = event.srcElement.selectionStart;
      let endPosition = event.srcElement.selectionEnd;
      let len = event.srcElement.value.length;
      let sel = event.srcElement.value.substring(startPosition, endPosition);

      let value = event.srcElement.value.substring(0, startPosition) + paste + event.srcElement.value.substring(endPosition, len);  
      this.value = value;
      event.srcElement.value = value;

      
      const evt = new CustomEvent('change', {
        bubbles: true,
        detail: value
      });  
      this.taskQueue.queueMicroTask(() => {       
        this.element.dispatchEvent(evt);  
      });
    }   

    return false;    
  }

  private blur(e) {    
    if (this.value) {
      this.value = this.value.toString().replace(',','.');
    }
  }

  private keydown (e) {    
    if (this.isNavigationOrSelectionKey(e)) {
      const evt = new CustomEvent('value-changed', {
        bubbles: true,
        detail: this.value
      });  
      this.taskQueue.queueMicroTask(() => {       
        this.element.dispatchEvent(evt);  
      });
      return true;
    }
    
    // If it's not a number, prevent the keypress...
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
       return false;
    } else {
      const newValue = this.preventNegativeNumbers ? Math.abs(parseInt(this.value)).toString() : this.value;
      const evt = new CustomEvent('value-changed', {
        bubbles: true,
        detail: newValue
      });  
      this.taskQueue.queueMicroTask(() => {       
        this.element.dispatchEvent(evt);  
      });
      return true;
    }
  }

  private isNavigationOrSelectionKey(e: KeyboardEvent) {        
    // Allow: backspace, delete, tab, escape, enter, comma and ., but only allow 1 comma, dot or dash
    if (
      ([110, 188, 190].indexOf(e.keyCode) !== -1 && (this.value ? this.value.toString().indexOf('.') === -1 && this.value.toString().indexOf(',') === -1 : true)) ||
      ([46, 8, 9, 27, 13, 109].indexOf(e.keyCode) !== -1 && (this.value ? this.value.toString().indexOf(e.key) === -1 : true)) ||
      // Allow: Ctrl+A/X/C/V, Command+A/X/C/V
      ([65, 67, 86, 88].indexOf(e.keyCode) !== -1 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      (!this.preventNegativeNumbers && e.keyCode === 189))      
      
      {
      // let it happen, don't do anything
      return true;
    }
    return false;
  }

  public keyup(event) {
    if (this.allowValueChangeByArrows) {
      let newValue = parseInt(this.value);
      // Arrow up
      if(event.keyCode === 38 && Number.isInteger(newValue)) {
        newValue = newValue + 1;
        this.value = newValue.toString();
      }
      // Arrow down
      if(event.keyCode === 40 && Number.isInteger(newValue)) {
        if(this.preventNegativeNumbers){
          newValue = newValue > 1 ? Math.abs(newValue - 1) : 0;
        }
        else{
          newValue = newValue - 1;
        }
        this.value = newValue.toString();
      }
    }
    if (event.keyCode === 188 || event.keyCode === 110) {
      this.value = this.value.toString().replace(',','.');
    }
  }

  public searchEvent(event){
    this.value = event.target.value;
    const evt = new CustomEvent('value-changed', {
      bubbles: true,
      detail: this.value
    });  
    this.taskQueue.queueMicroTask(() => {       
      this.element.dispatchEvent(evt);  
    });

  }

  public blurEvent(event){
    const evt = new CustomEvent('blur', {
      bubbles: true,
      detail: this.value
    });  
    this.taskQueue.queueMicroTask(() => {       
      this.element.dispatchEvent(evt);  
    });

  }

}

@customAttribute('optional-placeholder')
export class optionalPlaceholderCustomAttribute {
  
  static inject = [Element];
  
  constructor(private element: Element) {
    this.element = element;
  }
  
  valueChanged(newValue) {
    if (newValue) {
      this.element.setAttribute('placeholder', newValue);
    } else {
      this.element.removeAttribute('placeholder');
    }
  }
  
}

import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { bindable, computedFrom, DOM, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { PubSub } from 'lib/event/PubSub';

const logger: Logger = LogManager.getLogger('tabs');

@inject(DOM.Element, EventAggregator, Router, PubSub)
export class Tabs {
  private element: HTMLElement;
  private tabsEl: Array<HTMLElement>;
  protected tabsHeaders: Array<{
    id?: string;
    meta?: string;
    name: string;
    css: {
      width: string;
    };
  }>;
  @bindable private activeTabIdx: number;
  @bindable private noRouter: boolean = false;
  @bindable private canDeactivateExternal: () => Promise<boolean> | boolean;

  private nextTabSubscription: Subscription;
  private prevTabSubscription: Subscription;
  private changeTabSubscription: Subscription;
  private refreshTabsSubscription: Subscription;

  constructor(
    DOMElement: HTMLElement,
    private eventAggregator: EventAggregator,
    private router: Router,
    private pubsub: PubSub
  ) {
    this.element = DOMElement;
    this.tabsEl = [];
    this.activeTabIdx = 0;
  }

  setTabHeaders() {
    this.tabsHeaders = this.tabsEl.map((t) => {
      return {
        name: t.getAttribute('name'),
        id: t.getAttribute('id'),
        css: { width: `${this.tabWidth}%` },
      };
    });
  }

  updateTabHeaders() {
    this.tabsHeaders = this.tabsEl.map((t) => {
      return {
        id: t.getAttribute('id'),
        name: t.getAttribute('name'),
        css: { width: `${this.tabWidth}%` },
      };
    });
  }

  @computedFrom('tabsEl', 'activeTabIdx')
  get slideCss() {
    const width = `${this.tabWidth}%`;
    return {
      width,
      left: `calc(${this.activeTabIdx} * ${width})`,
    };
  }

  get tabWidth() {
    const empty: Array<HTMLElement> = [];
    return 100 / ((this.tabsEl || empty).length || 0);
  }

  protected attached() {
    this.pubsub.sub('tabs-meta:changed', (data) => {
      for (const m of data) {
        const entry = this.tabsHeaders.find((t) => t.id && t.id === m.id);
        if (!entry) continue;
        entry.meta = m.meta;
      }

      this.tabsHeaders = this.tabsHeaders.slice();
    });

    this.nextTabSubscription = this.eventAggregator.subscribe('triggerNextTab', () => {
      this.nextTab();
    });

    this.prevTabSubscription = this.eventAggregator.subscribe('triggerPrevTab', () => {
      this.prevTab();
    });

    this.changeTabSubscription = this.eventAggregator.subscribe('changeTab-success', (tabIndex: number) => {
      if (tabIndex == null || tabIndex == undefined) {
        return;
      }
      this.activeTabIdx = tabIndex;
    });

    this.refreshTabsSubscription = this.eventAggregator.subscribe('refresh-tabs', () => {
      setTimeout(() => {
        this.tabsEl = [].slice.call(this.element.querySelectorAll('tab'));
        this.setTabHeaders();
      }, 500);
    });

    if (!this.element) {
      return;
    }
    this.tabsEl = [].slice.call(this.element.querySelectorAll('tab'));
    this.setTabHeaders();

    if (this.router.currentInstruction.config.tabindex === 0) {
      this.changeTab(0, false);
      return;
    }
    if (this.router.currentInstruction.config.tabindex) {
      this.activeTabIdx = this.router.currentInstruction.config.tabindex;
      this.changeTab(this.router.currentInstruction.config.tabindex);
    } else {
      this.changeTab(0, false);
    }
  }

  protected detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
    if (this.refreshTabsSubscription) {
      this.refreshTabsSubscription.dispose();
    }
    if (this.nextTabSubscription) {
      this.nextTabSubscription.dispose();
    }
    if (this.prevTabSubscription) {
      this.prevTabSubscription.dispose();
    }

    this.pubsub.unsub();
  }

  protected deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
    if (this.refreshTabsSubscription) {
      this.refreshTabsSubscription.dispose();
    }
    if (this.nextTabSubscription) {
      this.nextTabSubscription.dispose();
    }
    if (this.prevTabSubscription) {
      this.prevTabSubscription.dispose();
    }
  }

  private nextTab() {
    logger.debug('activeTabIdx', this.activeTabIdx);
    this.changeTab(this.activeTabIdx + 1);
  }

  private prevTab() {
    this.changeTab(this.activeTabIdx - 1);
  }

  private changeTab(index: number, changeRoute: boolean = true) {
    const currentTab = this.tabsEl[this.activeTabIdx] ? this.tabsEl[this.activeTabIdx] : 0;
    this.tabsEl.forEach((t, i) => {
      if (index === i) {
        t.setAttribute('data-active', 'true');

        if (t.getAttribute('data-route') && changeRoute) {
          const targetRoute = t.getAttribute('data-route');
          const instruction = this.router.currentInstruction.config.name;
          const params = targetRoute === instruction ? this.router.currentInstruction.queryParams : {};

          this.router.navigateToRoute(t.getAttribute('data-route'), params);
          if (currentTab && currentTab.getAttribute('data-nav-guard')) {
            this.eventAggregator.publish('changeTab', index);
          } else {
            this.activeTabIdx = index;
          }
        } else {
          if (this.noRouter && changeRoute == true) {
            const res = this.canDeactivateExternal();
            if (typeof res === 'boolean') {
              if (res === true) {
                this.eventAggregator.publish('changeTab', index);
                this.activeTabIdx = index;
              }
            } else {
              res.then((result) => {
                if (result === true) {
                  this.eventAggregator.publish('changeTab', index);
                  this.activeTabIdx = index;
                }
              });
            }
          } else {
            this.eventAggregator.publish('changeTab', index);
            this.activeTabIdx = index;
          }
        }
      } else {
        t.removeAttribute('data-active');
      }
    });
  }
}

import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject()
export class ProgressButton {
  @bindable public classes: string;
  @bindable public text: string;
  @bindable public progress = false;
  @bindable public disabled = false;

  public translatedText: string;

  constructor(
    private i18n: I18N,
    private eventAggregator: EventAggregator
  ) {
    this.eventAggregator.subscribe('i18n:locale:changed', () => {
      this.translatedText = this.i18n.tr(this.text);
    });
  }

  bind() {
    this.translatedText = this.i18n.tr(this.text);
  }

  textChanged() {
    this.translatedText = this.i18n.tr(this.text);
  }
}

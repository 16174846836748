import { autoinject } from 'aurelia-dependency-injection';
import { connectTo } from 'aurelia-store';
import { bindable } from 'aurelia-templating';
import { ApplicationState } from 'lib/state';
import { Role } from 'models/UserModels';

@autoinject()
@connectTo()
export class RequiresRole {
  @bindable() private roles: Role[];
  public roleCheckPassed = false;
  private state: ApplicationState;

  attached() {
    this.roleCheckPassed = !!this.state.roles.find((userRole) => {
      return this.roles.includes(userRole);
    });
  }
}

import { autoinject } from 'aurelia-framework';
import { connectTo } from 'aurelia-store';
import { ApplicationState } from 'lib/state';

@autoinject()
@connectTo()
export class Spinner {
  state: ApplicationState;

  constructor() {
    this.state?.sidebarOpen;
  }
}

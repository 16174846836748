import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

export type PreviewArgs = {
  blob: Blob;
  fileName: string;
};

@autoinject
export class PdfPreview {
  protected pdfUrl: string;

  constructor(private dialogController: DialogController) { }

  protected activate(model: PreviewArgs) {
    if (!model.blob) {
      void this.dialogController.cancel();
    }
    const blob = new Blob([model.blob], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    this.pdfUrl = url;
  }
}

import { autoinject, bindable } from 'aurelia-framework';
import './status-dot.scss';
import { I18N } from 'aurelia-i18n';

@autoinject
export class StatusDot {
  @bindable()
  protected status: 'ok' | 'error' | 'warning' | 'info' = 'ok';

  @bindable()
  tooltip: string | undefined;

  _tooltip: string | undefined;

  constructor(private t: I18N) {}

  attached() {
    this._tooltip = this.tooltip ? this.t.tr(this.tooltip) : undefined;
  }
}

import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class Tab {
  @bindable
  name: string;

  @bindable
  active: boolean;

  @bindable
  if: boolean;
}

import { autoinject, bindable, observable } from 'aurelia-framework';
import { FileAttachmentModels } from 'models/FileAttachmentModels';
import { SystemFileStorageService } from 'services/system-file-storage-service';
import { PdfPreviewController } from './pdf-preview-controller';
import { AsyncErrorHandler } from 'lib/ui';

@autoinject
export class SystemFile {
  @bindable()
  @observable()
  fileId?: number;
  fileIdChanged(fileId: number) {
    if (!fileId) return;
    void this.getMetaData(fileId);
  }

  @bindable()
  fileOnly = false;

  @bindable()
  onFileDelete: () => void;

  @bindable()
  onFileSelected: ({ file }: { file: File }) => void;

  @bindable
  disabled = false;

  @bindable()
  accept = '.pdf';

  isUploading = false;

  showDownload = true;

  protected isPdf = false;

  protected fileMetaData: FileAttachmentModels.FileAttachment = undefined;

  constructor(
    private systemFileStorageService: SystemFileStorageService,
    private pdfPreviewController: PdfPreviewController
  ) { }

  protected updateFilesSelect(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target.files[0];
    this.onFileSelected?.({ file });
  }

  @AsyncErrorHandler
  protected async previewFile() {
    const file = (await this.systemFileStorageService.download(this.fileId, false)) as Blob;
    void this.pdfPreviewController.openPdfPreview({
      blob: file,
      fileName: this.fileMetaData.FileName,
    });
  }

  @AsyncErrorHandler
  protected async downloadFile() {
    await this.systemFileStorageService.download(this.fileId, true);
  }

  protected async getMetaData(fileId: number) {
    this.fileMetaData = await this.systemFileStorageService.getMetaData(fileId);
    this.isPdf = this.fileMetaData.FileName?.endsWith('.pdf') ?? false;
  }
}

import { autoinject, bindable, observable } from "aurelia-framework";
import { BaseEntityWithMultilanguageName } from "models/BaseEntity";
type Options = BaseEntityWithMultilanguageName &{default: boolean}
@autoinject()
export class RadioButton {
    @bindable label: string
    @bindable options: Options [] = []
    @bindable selected?: number
    @bindable name: string

    @bindable
    @observable
    defaultSelected: number

    defaultSelectedChanged(){
        setTimeout(() => {
            if(!Number.isInteger(this.selected)){
                this.onChanged({Id: this.defaultSelected} as BaseEntityWithMultilanguageName)
            }
        });
    }

    constructor(private element: Element) {  
        this.element = element;
    }

    onChanged(option: BaseEntityWithMultilanguageName) {  
    const changeEvent = new CustomEvent('radio-changed', {
        detail: { id: option.Id},
        bubbles: true,
      });
      this.element.dispatchEvent(changeEvent);
    }
}
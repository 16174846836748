import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject
export class Prompt {
  private message: string;  
  private header: string;
  private actions = {
    delete: { enabled: false, t: 'dialog.delete' },
    save: { enabled: true, t: 'dialog.save' },
    dontsave: { enabled: true, t: 'dialog.dontsave' },
    cancel: { enabled: true, t: 'dialog.cancel' },
    continue: { enabled: false, t: 'dialog.continue' },
    createNets: { enabled: false, t: 'order.confirmCreateNets' }
  };

  constructor(private controller: DialogController, private i18n: I18N) {
  }

  public activate(model) {
    if (model.actions) {
      this.actions = model.actions;
    }
    if (!model.messagePreparsed) {
      this.message = this.i18n.tr(model.message).split('\n').join('<br/>');
    } else {
      this.message = model.message.split('\n').join('<br/>');
    }

    this.header = this.i18n.tr(model.header) ;
  }
}

import { DialogService } from 'aurelia-dialog';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { PdfPreview, PreviewArgs } from './pdf-preview';
PLATFORM.moduleName('./pdf-preview');

@autoinject
export class PdfPreviewController {
  constructor(private dialogService: DialogService) { }

  openPdfPreview(blob: PreviewArgs) {
    return this.dialogService
      .open({
        viewModel: PdfPreview,
        model: blob,
        position: () => 0,
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
      });
  }
}

import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

@autoinject()
export class TableActionButton {
  @bindable()
  disabled: boolean = false;

  @bindable()
  onClick: VoidFunction;

  @bindable()
  classes: string = '';

  @bindable()
  tooltip: string |undefined;

  _tooltip: string | undefined;


  constructor(
    private t: I18N
  ) {
    //
  }

  attached() {
    if (this.tooltip){
      this._tooltip = this.t.tr(this.tooltip);
    }
  }

}

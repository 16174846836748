import { bindable, bindingMode } from 'aurelia-framework';
import { connectTo } from 'aurelia-store';
import { ApplicationState } from 'lib/state';

@connectTo()
export class Pagination {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) private currentPage: number | undefined;

  @bindable private pageSize: number | undefined;
  @bindable private totalItems: number | undefined;
  @bindable private paginationSize: number | undefined;
  @bindable protected hideSinglePage = true;
  @bindable protected boundaryLinks = false;
  @bindable protected firstText = 'First';
  @bindable protected lastText = 'Last';
  @bindable protected directionLinks = true;
  @bindable protected previousText = '<';
  @bindable protected nextText = '>';

  protected state: ApplicationState;
  stateChanged(newState: ApplicationState, oldState: ApplicationState) {
    if (!oldState) {
      this.pageSize = newState?.listSettings?.pageSize || 25;
    } else if (newState.listSettings.pageSize !== oldState.listSettings.pageSize) {
      this.pageSize = newState.listSettings.pageSize;
    }
  }

  protected totalPages = 1;
  protected displayPages = [];

  // Aurelia hooks
  protected bind() {
    if (typeof this.currentPage === 'string') {
      this.currentPage = parseInt(this.currentPage, 10);
    }
    if (this.currentPage === undefined || this.currentPage === null || this.currentPage < 1) {
      this.currentPage = 1;
    }
  }

  // Aurelia hooks
  protected attached() {
    if (this.pageSize === undefined || this.pageSize === null || this.pageSize < 1) {
      this.pageSize = this.state.listSettings.pageSize || 5;
    }

    this.calculatePages();
  }

  protected totalItemsChanged() {
    this.calculatePages();
  }

  protected pageSizeChanged() {
    this.calculatePages();
  }

  protected currentPageChanged() {
    this.calculatePages();
  }

  private calculatePages() {
    if (this.pageSize === 0) {
      this.totalPages = 1;
    } else {
      this.totalPages = this.totalItems <= this.pageSize ? 1 : Math.ceil(this.totalItems / this.pageSize);
    }

    if (isNaN(this.paginationSize) || this.paginationSize <= 0) {
      this.displayAllPages();
    } else {
      this.limitVisiblePages();
    }
  }

  private displayAllPages() {
    const displayPages = [];

    for (let i = 1; i <= this.totalPages; i++) {
      displayPages.push({
        title: i.toString(),
        value: i,
      });
    }
    this.displayPages = displayPages;
  }

  private limitVisiblePages() {
    const displayPages = [];

    const totalTiers = Math.ceil(this.totalPages / this.paginationSize);

    const activeTier = Math.ceil(this.currentPage / this.paginationSize);

    const start = (activeTier - 1) * this.paginationSize + 1;
    const end = start + this.paginationSize;

    if (activeTier > 1) {
      displayPages.push({
        title: '...',
        value: start - 1,
      });
    }

    for (let i = start; i < end; i++) {
      if (i > this.totalPages) {
        break;
      }

      displayPages.push({
        title: i.toString(),
        value: i,
      });
    }

    if (activeTier < totalTiers) {
      displayPages.push({
        title: '...',
        value: end,
      });
    }

    this.displayPages = displayPages;
  }

  protected selectPage(page: number) {
    if (page < 1 || page > this.totalPages || page === this.currentPage) {
      return;
    }

    this.currentPage = page;
  }

  protected nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  protected previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  protected firstPage() {
    this.currentPage = 1;
  }

  protected lastPage() {
    this.currentPage = this.totalPages;
  }
}
